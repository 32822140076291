import { render, staticRenderFns } from "./ProductosFormPrecioCantidad.vue?vue&type=template&id=cc4baedc&scoped=true&"
import script from "./ProductosFormPrecioCantidad.vue?vue&type=script&lang=js&"
export * from "./ProductosFormPrecioCantidad.vue?vue&type=script&lang=js&"
import style0 from "./ProductosFormPrecioCantidad.vue?vue&type=style&index=0&id=cc4baedc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc4baedc",
  null
  
)

export default component.exports