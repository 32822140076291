<template>
  <div>
     <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form margin-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.opcionNeto"
          :key="item.opcionNeto"
          ref="row"
        >
          <b-col md="1">
          </b-col>
          <!-- RANGO ID -->
          <b-col md="1">
            <b-form-group
              label="#"
              :label-for="'id-'+item.opcionNeto"
              class="text-center"
            >
              <b-form-input
                v-model="item.opcionNeto"
                :id="'id-'+item.opcionNeto"
                class="text-center"
                type="number"
                plaintext
              />
            </b-form-group>
          </b-col>

          <!-- CANTIDAD -->
          <b-col md="3">
            <b-form-group
              label="Cantidad Desde"
              :label-for="'cantidad-'+item.opcionNeto"
            >
              <b-form-input
                v-model="item.cantidad"
                :id="'cantidad-'+item.opcionNeto"
                type="text"
                @blur="soloNumerosCantidad(item)"
                @keyup="item.cantidad = soloNumeros(item.cantidad)"
                :disabled="item.opcionNeto === 1"
              />
            </b-form-group>
          </b-col>

          <!-- PRECIO -->
          <b-col md="5">
            <b-form-group
              label="Valor Unitario"
              :label-for="'precio-'+item.opcionNeto"
            >
              <b-input-group
                prepend="$"
              >
                <cleave
                  :id="'precio-'+item.opcionNeto"
                  v-model="item.precio"
                  class="form-control"
                  @input="item.precio = soloNumeros(item.precio)"
                  @blur.native="item.precio = soloNumeros(item.precio)"
                  @keyup.native="item.precio = soloNumeros(item.precio)"
                  :options="options.number"
                />
              </b-input-group>
              <!-- <b-form-input
                v-model="item.precio"
                :id="'precioHidden-'+item.opcionNeto"
                type="text"
                @blur="item.precio = soloNumeros(item.precio)"
                @change="item.precio = soloNumeros(item.precio)"
                hidden
              /> -->
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="1"
            md="1"
            class="mb-50"
          >
            <b-button
              v-if="Number(item.opcionNeto) !== items.length || item.opcionNeto === 1"
              variant="outline-danger"
              class="mt-0 mt-md-2 mb-sm-1 btn-sm-block"
              disabled
              size="sm"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2 mb-sm-2 btn-sm-block"
              @click="removeItem(index)"
              size="sm"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
            </b-button>
          </b-col>
          <colLinea class="margin-hr" ancho="xs"/>
        </b-row>
      </b-form>
    </div>

    <!-- ADD BUTTON -->
    <b-row>
      <b-col lg="1">
      </b-col>
      <b-col>
        <b-button
          id="addButton"
          variant="primary"
          class="mt-2 mb-2 ml-md-2 btn-sm-block"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          @click="repeateAgain"
          :disabled="btnAddDisabled"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BInputGroup,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { formatos } from '@/mixins/forms'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

const colLinea = () => import('@/layouts/components/Recycled/Form/colLinea.vue')

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    Cleave,

    colLinea,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition, formatos],
  props: {
    preciosCantidad: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      items: [{}],
      nextTodoId: 1,
      btnAddDisabled: false,
      options: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  watch: {
    items() {
      this.$emit('update:preciosCantidad', this.items)
    },
  },
  mounted() {
    this.items = this.preciosCantidad
    if (this.preciosCantidad.length > 1) {
      this.nextTodoId = this.preciosCantidad.length
    }
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    soloNumerosPrecio(item) {
      const index = Number(item.opcionNeto) - Number(1)
      if (this.items[index].precio != null) {
        if (this.items[index].precio === '') {
          this.items[index].precio = 1
        }
        const expRegNumero = /[^\d]/g
        const validation = expRegNumero.test(item.precio)
        if (item.precio === undefined || item.precio === '' || validation) {
          this.items[index].precio = this.items[index].precio.replace(expRegNumero, '')
        }
      }
    },
    soloNumerosCantidad(item) {
      const index = Number(item.opcionNeto) - Number(1)
      if (this.items[index].cantidad != null) {
        const expRegNumero = /[^\d]/g
        const validation = expRegNumero.test(item.cantidad)
        if (item.cantidad === undefined || item.cantidad === '' || validation) {
          this.items[index].cantidad = this.items[index].cantidad.replace(expRegNumero, '')
        }
      }
      this.menorAMayorCantidad()
    },
    menorAMayorCantidad() {
      if (this.items.length > 1) {
        this.items.forEach(item => {
          if (item.opcionNeto >= 1) {
            const index = Number(item.opcionNeto)
            const indexAnterior = Number(item.opcionNeto) - Number(1)
            if (item.opcionNeto < this.items.length) {
              if (Number(this.items[indexAnterior].cantidad) >= Number(this.items[index].cantidad)) {
                this.items[index].cantidad = Number(this.items[indexAnterior].cantidad) + Number(1)
              }
            }
          }
        })
      }
    },
    repeateAgain() {
      this.btnAddDisabled = false
      this.items.push({
        opcionNeto: this.nextTodoId += 1,
        cantidad: 1,
        precio: 1,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })

      this.menorAMayorCantidad()
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.nextTodoId -= 1
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.margin-hr{
  margin-top: -2px;
}
.margin-form{
  margin-top: 10px;
}
</style>
